<template>
  <div>
    <ez-button>jjj</ez-button>
    <ez-avatar size="large" name="Alice Ren"></ez-avatar>
    <ez-date-picker
      class="datetime-picker-input-range"
      v-model="value1"
      size="medium"
      type="datetimerange"
      start-placeholder="Start Time"
      end-placeholder="End Time"
      format="yyyy-MM-dd HH:mm:ss"
      :default-time="['12:00:00']">
    </ez-date-picker>
    <ez-alert type="info" title="This is a normal message" :closable="false" show-icon/>

    <ez-icon icon="Icon_Doc" />

    <ez-icon icon="Icon_Shop_Ebay" size="20px" style="color: red" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      value1: '',
      value2: ''
    }
  }
}
</script>

<style scoped>

</style>
